import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  contentFullWidth,
  contentHorizontalPadding,
  boldFontFamily,
  fullWidth,
  mobileSize,
} from '../components/base_styles';
import ArrowButton from '../components/ArrowButton';
import Categories from '../components/home/Categories';
import DeconstructedLogo from '../components/home/DeconstructedLogo';
import Quotes from '../components/home/Quotes';
import SayHello from '../components/home/SayHello';
import FeaturedCaseStudy from '../components/case-studies/FeaturedCaseStudy';
import Layout from '../components/layout/Layout';
import SEO from '../components/Seo';

const IntroOverflow = styled.div`
  overflow-x: hidden;
  width: 100%;
`;

const Intro = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: ${contentFullWidth};
  padding: 209px ${contentHorizontalPadding} 183px ${contentHorizontalPadding};

  @media (max-width: ${mobileSize}) {
    padding: 48px ${contentHorizontalPadding} 10px;
  }
`;

const IntroTextWrapper = styled.div`
  padding-right: 20px;
`;

const IntroHeading = styled.h1`
  color: #4F4D56;
  font-family: ${boldFontFamily};
  font-size: 40px;
  line-height: 48px;
  margin: 0 0 34px 0;
  max-width: 430px;

  @media (max-width: ${mobileSize}) {
    font-size: 32px;
    line-height: 40px;
  }
`;

const IntroText = styled.p`
  color: black;
  font-size: 21px;
  letter-spacing: 0.25px;
  line-height: 32px;
  margin: 0 0 34px 0;
  max-width: 540px;

  @media (max-width: ${mobileSize}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const CaseStudyWrapper = styled.div`
  margin: 0 auto;
  max-width: ${fullWidth};
  padding: 88px ${contentHorizontalPadding};

  @media (max-width: ${mobileSize}) {
    padding: 48px ${contentHorizontalPadding};
  }
`;

const shadowColors = ['#C5F9E9', '#FCEECA'];

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const caseStudies = edges
    .map((edge, index) => (
      <FeaturedCaseStudy
        key={edge.node.id}
        caseStudy={edge.node}
        backgroundColor={shadowColors[index]}
      />
    ));

  return (
    <Layout>
      <SEO title="Home" />
      <IntroOverflow>
        <Intro>
          <IntroTextWrapper>
            <IntroHeading>
              Design for the brands doing things differently.
            </IntroHeading>
            <IntroText>
              I create visual identities and communications for purposeful brands who place
              people at the heart of everything they say and do.
            </IntroText>
            <ArrowButton link="/about/" text="More About me" />
          </IntroTextWrapper>
          <DeconstructedLogo />
        </Intro>
      </IntroOverflow>

      <CaseStudyWrapper>
        { caseStudies }
      </CaseStudyWrapper>

      <Categories />

      <Quotes />

      <SayHello title="Say hello…">
        If you have a project brief you’d like to discuss or if you have any questions
        then please send an email to hello@beckyalford.co.uk
      </SayHello>

    </Layout>
  );
};

export default IndexPage;

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      /* eslint-disable-next-line react/forbid-prop-types */
      edges: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2, sort: { order: DESC, fields: [frontmatter___date] }) {
      ...caseStudyFragment
    }
  }
`;
