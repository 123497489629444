import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { semiBoldFontFamily } from '../base_styles';

const HrefButton = styled.a`
  color: #2ADCC7;
  display: inline-block;
  font-family: ${semiBoldFontFamily};
  font-size: 17px;
  letter-spacing: 1.5px;
  line-height: 20px;
  padding: 16px 0px;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out;

  svg {
    padding-left: 8px;
  }

  path {
    transition: stroke 0.2s ease-in-out;
  }

  &:hover {
    color: #1BA392;

    path {
      stroke: #1BA392;
    }
  }
`;

const ArrowButton = ({ link, text }) => (
  <HrefButton href={link}>
    {text}
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 7L16.2857 7" stroke="#56D6CC" strokeWidth="2" />
      <path d="M11.1426 13L17.1426 7L11.1426 1" stroke="#56D6CC" strokeWidth="2" />
    </svg>
  </HrefButton>
);

ArrowButton.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ArrowButton;
