import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import useIsMobile from '../../hooks/use-is-mobile';
import { mobileSize } from '../base_styles';

const DeconstructedLogoWrapper = styled.div`
  margin-left: 65px;
  position: relative;

  @media (max-width: 1080px) {
    margin-left: 30px;
  }

  @media (max-width: ${mobileSize}) {
    display: none;
  }
`;

const calcScrollPercentage = (scrollTop, iconTop) => {
  const scrolledPercentage = (1 - scrollTop / iconTop) * 100;
  return Math.max(Math.min(scrolledPercentage, 100), 0);
};

const calcDistance = (maxDistance, percent) => Math.max((maxDistance / 100) * percent, 0);

const NAVBAR_OFFSET = 80;

const DeconstructedLogo = () => {
  const [iconTop, setIconTop] = useState(null);
  const [{ scrollPercent }, set] = useSpring(() => ({ scrollPercent: 0 }));
  useScrollPosition(({ currPos }) => {
    const scrollTop = currPos.y * -1 || 0;
    if (iconTop) {
      set({ scrollPercent: calcScrollPercentage(scrollTop, iconTop) });
    }
  }, [iconTop], null, false, 200);

  const isMobile = useIsMobile();

  if (isMobile) {
    return null;
  }

  const interpBYellow = scrollPercent.interpolate((percent) => {
    const maxYDistance = 100;
    const maxXDistance = 50;
    const transX = calcDistance(maxXDistance, percent) * -1;
    const transY = calcDistance(maxYDistance, percent) * -1;
    return `translate(${transX}px,${transY}px)`;
  });

  const interpBBlue = scrollPercent.interpolate((percent) => {
    const maxYDistance = 80;
    const maxXDistance = 50;
    const transX = calcDistance(maxXDistance, percent);
    const transY = calcDistance(maxYDistance, percent);
    return `translate(${transX}px,${transY}px)`;
  });

  const interpBGrey = scrollPercent.interpolate((percent) => {
    const maxXDistance = 20;
    const transX = calcDistance(maxXDistance, percent);
    return `translate(${transX}px,0px)`;
  });

  const interpAPink = scrollPercent.interpolate((percent) => {
    const maxYDistance = 250;
    const maxXDistance = 24;
    const transX = calcDistance(maxXDistance, percent) * -1;
    const transY = calcDistance(maxYDistance, percent) * -1;
    return `translate(${transX}px,${transY}px)`;
  });

  const interpAGrey = scrollPercent.interpolate((percent) => {
    const maxXDistance = 50;
    const transX = calcDistance(maxXDistance, percent);
    return `translate(${transX}px,0px)`;
  });

  return (
    <DeconstructedLogoWrapper
      ref={(el) => {
        if (!el) return;
        setIconTop(window.pageYOffset + el.getBoundingClientRect().top - NAVBAR_OFFSET);
        set({ scrollPercent: calcScrollPercentage(0, iconTop) });
      }}
    >
      <animated.svg
        style={{
          transform: interpBYellow,
          position: 'absolute',
          zIndex: 6,
        }}
        width="68"
        height="241"
        viewBox="0 0 68 241"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M67.3969 0H0V240.189C38.72 230.043 67.5214 193.753 67.5214 150.367V0H67.3969Z"
          fill="#FFEFB0"
        />
      </animated.svg>

      <animated.svg
        style={{
          transform: interpBBlue,
          position: 'absolute',
          top: '169px',
          zIndex: 5,
        }}
        width="144"
        height="133"
        viewBox="0 0 144 133"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M144 130.123C144 45.6488 98.775 0 14.5023 0H0V133H144V130.123Z" fill="#77C6C0" />
      </animated.svg>

      <animated.svg
        style={{
          transform: interpBGrey,
          position: 'absolute',
          left: '100px',
          zIndex: 4,
        }}
        width="128"
        height="302"
        viewBox="0 0 128 302"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M90.8608 150.927C113.022 135.822 127.527 111.463 127.527 82.9125C127.547 36.6633 89.4705 0 42.4919 0H31.9715H0.306641V301.855H42.4919C88.8065 301.855 126.406 266.167 127.444 220.83L127.547 221.017C127.547 220.581 127.506 220.166 127.506 219.731C127.506 219.461 127.547 219.212 127.547 218.921C127.547 190.392 113.022 166.033 90.8608 150.927ZM90.0723 151.488C90.2175 151.384 90.3628 151.28 90.508 151.176L91.0475 152.172C90.7155 151.965 90.4043 151.716 90.0723 151.488Z" fill="#EBECED" />
      </animated.svg>

      <animated.svg
        style={{
          transform: interpAPink,
          position: 'absolute',
          left: '212px',
          zIndex: 3,
        }}
        width="208"
        height="302"
        viewBox="0 0 208 302"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M149.93 0L0.881348 301.772H115.962L207.471 116.484L149.93 0Z" fill="#FAE1D1" />
      </animated.svg>

      <animated.svg
        style={{
          transform: interpAGrey,
          position: 'absolute',
          top: '115px',
          left: '419px',
          zIndex: 2,
        }}
        width="92"
        height="186"
        viewBox="0 0 92 186"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.470703 186H92.0001L0.470703 0.712402V186Z" fill="#A4A2A2" />
      </animated.svg>
    </DeconstructedLogoWrapper>
  );
};

DeconstructedLogo.propTypes = {

};

export default DeconstructedLogo;
