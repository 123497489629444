import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ArrowWrapper = styled.div`
  cursor: pointer;
  outline: none;
`;

const ArrowLeft = ({ className, onClick }) => (
  <ArrowWrapper
    className={className}
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex="0"
  >
    <svg width="21" height="46" viewBox="0 0 21 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 45L2 23L20 0.999999" stroke="#0AC4AC" strokeWidth="2" strokeLinecap="round" />
    </svg>
  </ArrowWrapper>
);

ArrowLeft.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ArrowLeft.defaultProps = {
  className: undefined,
  onClick: undefined,
};

export default ArrowLeft;
