/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTransition, animated } from 'react-spring';
import styled from 'styled-components';

import {
  boldFontFamily,
  bookFontFamily,
  contentFullWidth,
  contentHorizontalPadding,
  mobileSize,
} from '../base_styles';
import ArrowLeft from '../icons/ArrowLeft';
import ArrowRight from '../icons/ArrowRight';

const QuotesOuterWrapper = styled.div`
  background: white;
  padding: 130px 0 90px 0;
  text-align: center;

  @media (max-width: ${mobileSize}) {
    padding: 48px 0 20px 0;
  }
`;

const QuoteWrapper = styled.div`
  margin: 0 auto;
  max-width: ${contentFullWidth};
  padding: 0 ${contentHorizontalPadding};
  text-align: center;

  @media (max-width: ${mobileSize}) {
    grid-gap: 40px;
    grid-template-columns: 100%;
  }
`;

const QuoteTitle = styled.h2`
  display: block;
  margin-bottom: 28px;
  width: 100%;
`;

const QuoteScrollerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  svg {
    margin-top: 24px;
  }

  @media (max-width: ${mobileSize}) {
    svg {
      width: 10px;
    }
  }
`;

const QuoteScroller = styled.div`
  display: grid;
  margin: 0 24px;
  min-height: 200px;
  overflow-x: hidden;
  position: relative;

  div {
    grid-column: 1;
    grid-row: 1;
    will-change: transform, opacity;
  }
`;

const Quote = styled.p`
  color: #454545;
  font-family: ${boldFontFamily};
  font-size: 26px;
  letter-spacing: -0.25px;
  line-height: 38px;
  text-align: center;

  @media (max-width: ${mobileSize}) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const QuoteName = styled.p`
  color: #979797;
  font-family: ${bookFontFamily};
  font-size: 18px;
  line-height: 26px;

  @media (max-width: ${mobileSize}) {
    font-size: 16px;
  }
`;

const quoteList = [
  ({ style }) => (
    <animated.div style={{ ...style }}>
      <Quote>
        “Becky was a pleasure to work with. She was professional, personable and had a real
        flair for thoughtful design that lifted our brand to the next level.”
      </Quote>
      <QuoteName>Lisa Ball, Together Co</QuoteName>
    </animated.div>
  ),
  ({ style }) => (
    <animated.div style={{ ...style }}>
      <Quote>
        “We launched Springback Magazine on a tight schedule, and really lucked out with Becky.
        She came up trumps with our visual identity and site design, later also designing our
        print edition. We’re super-happy with her work!”
      </Quote>
      <QuoteName>Sanjoy Roy, Springback Magazine</QuoteName>
    </animated.div>
  ),
  ({ style }) => (
    <animated.div style={{ ...style }}>
      <Quote>
        “Becky&apos;s talented, versatile and highly-organised. Whatever the brief, Becky brings a
        calm, expert approach, and listens carefully to the needs of everyone involved. You
        couldn&apos;t wish for a nicer person to have on your team.”
      </Quote>
      <QuoteName>Charlie Peverett, Wilsome</QuoteName>
    </animated.div>
  ),
];

const quoteCount = quoteList.length - 1;

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const Quotes = () => {
  const [quoteIndex, setQuoteIndex] = useState(0);
  const prevQuoteIndex = usePrevious(quoteIndex);

  const nextQuote = useCallback(
    () => setQuoteIndex((state) => (state < quoteCount ? state + 1 : 0)), [],
  );
  const prevQuote = useCallback(
    () => setQuoteIndex((state) => (state > 0 ? state - 1 : quoteCount)), [],
  );

  useEffect(() => {
    const timer = setInterval(nextQuote, 7000);
    return () => clearTimeout(timer);
  }, []);

  const transitions = useTransition(quoteIndex, (p) => p, {
    from: {
      opacity: 0,
      transform: `translate3d(${prevQuoteIndex < quoteIndex ? '100%' : '-100%'},0,0)`,
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0%,0,0)',
    },
    leave: {
      opacity: 0,
      transform: `translate3d(${prevQuoteIndex < quoteIndex ? '-50%' : '50%'},0,0)`,
    },
  });

  return (
    <QuotesOuterWrapper>
      <QuoteWrapper>
        <QuoteTitle>What others say about me</QuoteTitle>
        <QuoteScrollerWrapper>
          <ArrowLeft onClick={prevQuote} />
          <QuoteScroller>
            {transitions.map(({ item, props, key }) => {
              const QuoteItem = quoteList[item];
              return <QuoteItem key={key} style={props} />;
            })}
          </QuoteScroller>
          <ArrowRight onClick={nextQuote} />
        </QuoteScrollerWrapper>
      </QuoteWrapper>
    </QuotesOuterWrapper>
  );
};

export default Quotes;
