import { useThrottledFn, useWindowResize } from 'beautiful-react-hooks';
import { useState, useEffect } from 'react';
import { mobileSize } from '../components/base_styles';

const mobileSizeInt = parseInt(mobileSize.replace('px', ''), 10);

const useIsMobile = () => {
  // returns true or false if browser window is mobile size

  const [isMobile, setIsMobile] = useState(false);

  const checkSize = () => {
    if (window.innerWidth > mobileSizeInt) {
      if (isMobile) {
        setIsMobile(false);
      }
    } else if (!isMobile) {
      setIsMobile(true);
    }
  };

  useEffect(checkSize, []);

  useWindowResize(useThrottledFn(checkSize));

  return isMobile;
};

export default useIsMobile;
