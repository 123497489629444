import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

import { contentFullWidth, contentHorizontalPadding, mobileSize } from '../base_styles';

const CategoriesOuterWrapper = styled.div`
  background: #C5F9E9;
  padding: 92px 0;

  @media (max-width: ${mobileSize}) {
    padding: 48px 0;
  }
`;

const CategoryTitle = styled.h1`
  margin:0 auto;
  margin-bottom: 28px;
  max-width: ${contentFullWidth};
  padding: 0 ${contentHorizontalPadding};
  text-align: center;
`;

const CategoriesWrapper = styled.div`
  display: grid;
  grid-gap: 58px;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  max-width: ${contentFullWidth};
  padding: 0 ${contentHorizontalPadding};
  text-align: center;

  @media (max-width: ${mobileSize}) {
    grid-gap: 40px;
    grid-template-columns: 100%;
  }
`;

const CategoryImg = styled(Img)`
  margin: 0 auto 5px auto;
  max-width: 150px;
`;

const CategoryHeader = styled.h4`
  margin-bottom: 8px;
`;

const Categories = () => {
  const data = useStaticQuery(graphql`
    fragment categoryImage on File {
      childImageSharp {
        fixed(width: 150, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    query {
      branding: file(relativePath: { eq: "what_i_do_brand.png" }) {
        ...categoryImage
      }
      print: file(relativePath: { eq: "what_i_do_print.png" }) {
        ...categoryImage
      }
      campaign: file(relativePath: { eq: "what_i_do_campaign.png" }) {
        ...categoryImage
      }
      website: file(relativePath: { eq: "what_i_do_website.png" }) {
        childImageSharp {
          fixed(width: 116, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <CategoriesOuterWrapper>
      <CategoryTitle>I create</CategoryTitle>
      <CategoriesWrapper>
        <div>
          <CategoryImg
            fixed={data.branding.childImageSharp.fixed}
            backgroundColor="#C5F9E9"
          />
          <CategoryHeader>Brand identities</CategoryHeader>
          <p>
            I help bring the essence of your organisation to life through an overarching brand
            concept, logo identity and visual toolkit. My process always starts with a true
            understanding of your organisation, it can be an explorative journey both for
            myself and my clients.
          </p>
        </div>
        <div>
          <CategoryImg
            fixed={data.campaign.childImageSharp.fixed}
            backgroundColor="#C5F9E9"
          />
          <CategoryHeader>Campaigns</CategoryHeader>
          <p>
            A creative campaign can be an opportunity for a brand to act bolder and braver. I design
            campaign identities, social graphics, video content and printed materials. It is
            rewarding to see people get involved with a campaign, whether online or on our streets.
          </p>
        </div>
        <div>
          <CategoryImg
            fixed={data.print.childImageSharp.fixed}
            backgroundColor="#C5F9E9"
          />
          <CategoryHeader>Printed materials</CategoryHeader>
          <p>
            I use a detailed eye when designing reports and publications to help define a
            photographic, illustrative and typographic approach. When producing smaller printed
            materials I’ll work with you to explore different print formats and ways of
            structuring your content.
          </p>
        </div>
        <div>
          <CategoryImg
            fixed={data.website.childImageSharp.fixed}
            backgroundColor="#C5F9E9"
            style={{ maxHeight: '100%' }}
          />
          <CategoryHeader>Websites</CategoryHeader>
          <p>
            I tailor my website process to the scale and requirements of each project. For larger
            projects I’ll review content, provide user personas, the website architecture,
            wireframes, and different design approaches. I’ll finally work with a developer
            to build the website and provide technical support.
          </p>
        </div>
      </CategoriesWrapper>
    </CategoriesOuterWrapper>
  );
};

export default Categories;
